<template>
  <div class="root">
    <div :style="biStyle"></div>
    <div class="containar">
      <el-card class="top">
        <h3>
          {{ sceneInfo.siteName }}
          <!-- <span>安全教育</span><span>1，2，3年级</span> -->
        </h3>
        <div class="top-content">
          <div class="left">
            <div style="display: flex; margin-bottom: 20px">
              <el-image
                style="
                  width: 400px;
                  height: 200px;
                  vertical-align: top;
                  margin-right: 30px;
                "
                :src="sceneInfo.sitePicture"
                fit="cover"
              ></el-image>
              <div style="flex: 1; line-height: 2em">
                场景简介：
                <p
                  style="
                    display: -webkit-box;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 5;
                    -webkit-box-orient: vertical;
                    text-indent: 2em;
                  "
                >
                  {{ sceneInfo.siteIntroduction }}
                </p>
              </div>
            </div>
          <!--  <p v-if="sceneInfo.siteHeat">
              <i class="el-icon-star-off"> </i> 场景热度：{{
                sceneInfo.siteHeat
              }}
            </p> -->
            <p>
              <i class="el-icon-connection"> </i> 人数限制：{{
                sceneInfo.siteLimitNumber >= 0
                  ? sceneInfo.siteLimitNumber
                  : "暂无限制"
              }}
            </p>
            <p v-if="sceneInfo.siteAddress">
              <i class="el-icon-location-outline"> </i> 场景地址：{{
                sceneInfo.siteAddress
              }}
            </p>
          </div>
          <div class="right">
            <button
              @click="handleAppointment(sceneInfo)"
              :class="sceneInfo.isOpen == 2 ? 'btn' : 'btn disabled'"
            >
              立即预约
            </button>
          </div>
        </div>
      </el-card>
      <el-card class="intro">
        <h4 class="title">场<span>景介</span>绍</h4>
        <div class="richText ql-editor" v-html="sceneInfo.siteDetail"></div>
      </el-card>
    </div>
  </div>
</template>

<script>
import bc from "@/assets/img/sceneInfoBc.jpg";
import { set } from "@/utils/local.js";
import { getSceneInfo_api } from "@/api/scene";
export default {
  data() {
    return {
      biStyle: {
        // 导航栏下面的通栏背景图
        height: "280px",
        backgroundImage: "url(" + bc + ")",
      },
      sceneInfo: "",
    };
  },
  methods: {
    // 获取数据
    async getSceneInfo() {
      let { data, code } = await getSceneInfo_api({
        siteId: this.$route.query.siteId,
      });
      if (code === 200) {
        // data.siteDetail = decodeURIComponent(data.siteDetail);
        this.sceneInfo = data;
        console.log(data);
      }
    },
    // 立即预约
    handleAppointment(item) {
      set("scene", item);
      if (item.isOpen !== 2) return this.$message.error("当前场景不可预约");
      if (!this.$store.state.isLogin) {
        // 如果没登录
        this.$router.push("/login?redirect=/scene/sceneAdd");
      } else if (
        this.$store.state.userInfo.roleName === "学生" ||
        this.$store.state.userInfo.roleName === "student"
      ) {
        // 是学生
        this.$confirm(
          "检测到你的帐号为学生！团队只支持教师帐号预约！你可以去看看有哪些课程可以预约?",
          "提示",
          {
            confirmButtonText: "去看看课程",
            cancelButtonText: "留在当前页",
            type: "warning",
          }
        ).then(() => {
          this.$router.push("/course");
        });
      } else {
        // 教师
        this.$router.push("/scene/sceneAdd");
      }
    },
  },
  created() {
    this.getSceneInfo();
  },
};
</script>

<style lang="less" scoped>
@btn-color: #f66866;
@title-color: #0079fe;
.containar {
  margin-top: -200px;
  .top {
    margin: 50px;
    h3 {
      font-size: 24px;
      font-weight: 700;
      color: @btn-color;
      margin: 20px;
    }
    .top-content {
      // display: flex;
      // justify-content: space-between;
      // align-items: center;
      position:relative;
      margin-left: 20px;
      .left {
        line-height: 2em;
        p {
          display: inline-block;
          min-width: 50%;
        }
      }
      .right {
        // width: 200px;
        text-align: center;
        position:absolute;
        bottom:0;
        right:0;
        .btn {
          margin-bottom: 10px;
          background-color: @btn-color;
          height: 30px;
          width: 100px;
          border-radius: 15px;
          &.disabled {
            background-color: #aaa;
            color: #fff;
          }
        }
      }
    }
  }
  .intro {
    margin-bottom: 100px;
    padding: 20px;
    line-height: 2em;
    .title {
      margin-bottom: 40px;
      font-weight: 700;
      font-size: 20px;
      text-align: center;
      color: @title-color;
      span {
        padding: 2px 0;
        font-weight: 700;
        border-bottom: 3px solid @title-color;
      }
    }
    .richText,
    .ql-editor {
      width: 80%;
      margin: 0 auto;
      // /deep/.ql-align-center {
      //   text-align: center;
      // }
      /deep/img {
        max-width: 100%;
      }
    }
  }
}
</style>
